import { Button } from "../../../components/button";
import styles from "./Buttons.module.css"
import printerIcon from "../../../assets/icons/printer-icon.svg";
import fileIcon from "../../../assets/icons/file-icon.svg";
import messageIcon from "../../../assets/icons/message-icon.svg";
import { usePropostaContext } from "../../../context/PropostaContext";

const FotterButtons = () => {
    const { proposta } = usePropostaContext();

    const handlePrint = () => {
        document.querySelector("#tabelaFluxo")?.scrollIntoView()
        setTimeout(() => {
            window.print();
        }, 500);
    };

    const whatsappText = `Olá, quero comercializar minha energia, tenho uma usina que a distribuidora é a ${proposta?.distribuidora.nome}, com potência de ${proposta?.potenciaInversor}kWh.\n\nAguardo seu contato.`;
    const whatsappLink = `https://wa.me/554499868139?text=${encodeURIComponent(whatsappText)}`;

    return (
        <div className={styles.buttonContainer}>
            <Button.Primary onClick={handlePrint}>
                <Button.Icon icon={printerIcon} />
                <Button.Label label="Imprimir" />
            </Button.Primary>
            <Button.Primary type="submit" href={whatsappLink}>
                <Button.Icon icon={messageIcon} />
                <Button.Label label="Entrar em contato" />
            </Button.Primary>
        </div>
    )
}

export default FotterButtons;