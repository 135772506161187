import React, { useEffect, useState } from 'react';
import Router from './Routes';
import { PropostaProvider } from './context/PropostaContext';
import { useSearchParams } from 'react-router-dom';

const searchParams = new URLSearchParams(window.location.search);
const propostaId = searchParams.get('id');

function App() {
  return (
    
    <PropostaProvider propostaId={propostaId}>
      <React.StrictMode>
        <Router />
      </React.StrictMode>
    </PropostaProvider>
  )
}

export default App