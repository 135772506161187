import React from "react";
import styles from "./Primary.module.css";

interface PrimaryProps {
  children: React.ReactNode;
  disabled?: boolean;
  className?: string;
  onClick?: () => void;
  type?: "button" | "submit";
  href?: string;
  target?: string;
}

export function Primary(props: PrimaryProps) {
  const ContainerComponent = props.href ? "a" : "button";

  const additionalProps = props.href
    ? { href: props.href, target: props.target || "_blank" }
    : {};

  return (
    <ContainerComponent
      type={props.type}
      onClick={props.disabled ? () => {} : props.onClick}
      className={`${styles.button} ${props.className} ${
        props.disabled ? styles.disable : ""
      }`}
      {...additionalProps}
    >
      {props.children}
    </ContainerComponent>
  );
}
