import React from 'react';
import styles from "./sectionFluxoCaixa.module.css";
import TableFluxoCaixa from "./tableFluxoCaixa/tableFluxoCaixa";
import tableIcon from "../../../assets/icons/table-icon.svg"
import HeaderPrinter from '../../../components/headerPrint/HeaderPrinter';
import linhasImg from "../../../assets/linhas-vetor.png";
import { usePropostaContext } from '../../../context/PropostaContext';


const SectionFluxoCaixa = () => {
    const { proposta } = usePropostaContext();

    return (
        <React.Fragment>
            <HeaderPrinter />
            <div className={styles.analiseSection}>
                <div className={styles.titleSection}>
                    <img
                        src={tableIcon}
                        alt=""
                        className={styles.tableIcon}
                    />
                    <div className={styles.container}>
                        <h1 className={styles.title}>Análise Financeira</h1>
                        <h2>Visão detalhada do fluxo de caixa.</h2>
                    </div>
                </div>
                <div className={styles.fluxoTableAndImage}>
                    {proposta && <TableFluxoCaixa distribuidora={proposta.distribuidora.dados} fluxoDeCaixa={proposta.fluxoDeCaixa} />}
                    <img src={linhasImg} className={styles.imgLinha} />
                </div>
            </div>
        </React.Fragment>
    );
}

export default SectionFluxoCaixa;
