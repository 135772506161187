import styles from "./OfertaFixa.module.css";
import flashLightIcon from "../../../assets/icons/light-icon.svg";
import PowerNumbersCard from "../../../components/powerNumbersCard/PowerNumberCards";
import { usePropostaContext } from "../../../context/PropostaContext";
import OfertaFixaMobile from "./OfertaFixaMobile";
const OfertaFixa = () => {
    const { proposta } = usePropostaContext();
    return (
        <div className={styles.bgSection1}>
            <div className={styles.sectionDesktop}>
                <div className={styles.section1Header}>
                    <div className={styles.titleSection}>
                        <img
                            src={flashLightIcon}
                            alt=""
                            className={styles.flashLightIcon}
                        />
                        <h1 className={styles.title}>Oferta fixa conforme tarifa vigente da distribuidora</h1>
                    </div>
                </div>
                <div className={styles.cardContainer}>
                    <PowerNumbersCard
                        textNumbers={proposta?.taxaMinimaPorMWhNaoDescontada != null
                            ? `${proposta?.taxaMinimaPorMWhNaoDescontada.toLocaleString('pt-BR', { currency: 'BRL' })} R$ / MWh`
                            : "..."}
                        fontWeight="600"
                    />
                    <PowerNumbersCard
                        textNumbers={proposta?.taxaMinimaPorMWhDescontada != null
                            ? `${proposta?.taxaMinimaPorMWhDescontada.toLocaleString('pt-BR', { currency: 'BRL' })} R$ / MWh`
                            : "..."}
                        textColor="#757575"
                        fontWeight="600"
                    />
                    <div className={styles.textRight}>Obtemos o valor líquido <br /> descontando a taxa <br /> mínima da distribuidora</div>
                </div>
                <div className={styles.textBelowContainer}>
                    <p>Valor bruto da oferta</p>
                    <p>Valor líquido da oferta</p>
                </div>
            </div>
            <div className={styles.sectionMobile}>
                <OfertaFixaMobile />
            </div>
            <div className={styles.divisionLine} />
        </div>
    )

}


export default OfertaFixa;