import styles from "./PremissasUsina.module.css";
import lampIcon from "../../../assets/icons/lamp-icon.svg";
import { usePropostaContext } from '../../../context/PropostaContext';
import PremissasUsinaMobile from "./PremissasUsinaMobile";

const PremissasUsina = () => {
    const { proposta } = usePropostaContext();
    return (
        <div className={styles.bgSection}>
            <div className={styles.section}>
                <div className={styles.sectionHeader}>
                    <div className={styles.contentContainer}>
                        <div className={styles.titleSection}>
                            <img
                                src={lampIcon}
                                alt=""
                                className={styles.lampIcon}
                            />
                            <div className={styles.boxGestaoPlin}>
                                <h1 className={styles.title}>Premissas da Usina</h1>
                            </div>
                        </div>
                        <div className={styles.resumoProposta}>
                            <div className={styles.boxInfosContainer}>
                                <div className={styles.boxInfos}>
                                    <div className={styles.boxInfosTitulos}>
                                        <h2>Capacidade Instalada</h2>
                                        <h2>Inflação Energética</h2>
                                        <h2>Perda de eficiência</h2>
                                        <h2>IPCA</h2>
                                        <h2>Overload</h2>
                                    </div>
                                    <div className={styles.boxInfosValores}>
                                        <h2>{proposta?.potenciaInversor != null
                                            ? `${proposta?.potenciaInversor}kW`
                                            : "..."}
                                        </h2>
                                        <h2>{proposta?.inflacaoEnergetica != null
                                            ? `${proposta ? Math.round(proposta?.inflacaoEnergetica * 100) : 0}% a.a`
                                            : "..."}
                                        </h2>
                                        <h2>{proposta?.perdaEficiencia != null
                                            ? `${proposta?.perdaEficiencia}% a.a`
                                            : "..."}
                                        </h2>
                                        <h2>{proposta?.ipca != null
                                            ? `${proposta?.ipca}% a.a`
                                            : "..."}
                                        </h2>
                                        <h2>{proposta?.overload != null
                                            ? `${proposta?.overload}%`
                                            : "..."
                                        }</h2>
                                    </div>
                                </div>
                                <div className={styles.boxInfos}>
                                    <div className={styles.boxInfosTitulos}>
                                        <h2>Potência Pico</h2>
                                        <h2>CAPEX [R$/MWp]</h2>
                                        <h2>CAPEX [R$]</h2>
                                        <h2>Regime Tributário</h2>
                                    </div>
                                    <div className={styles.boxInfosValores}>
                                        <h2>{proposta?.potenciaPico != null
                                            ? `${proposta?.potenciaPico} kWp`
                                            : "..."}
                                        </h2>
                                        <h2>{proposta?.realPorWp != null
                                            ? `${proposta?.realPorWp.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                                            : "..."}
                                        </h2>
                                        <h2>{proposta?.capex != null
                                            ? `${proposta?.capex.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                                            : "..."}
                                        </h2>
                                        <h2>{proposta?.regimeTributario != null
                                            ? proposta?.regimeTributario
                                            : "..."}
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.boxInfosContainerMobile}>
                                <PremissasUsinaMobile />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default PremissasUsina;
