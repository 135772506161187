import React, { ReactNode } from "react";
import styles from "./RoundTextBox.module.css";

interface RoundTextBoxProps {
  children: ReactNode;
}

const RoundTextBox: React.FC<RoundTextBoxProps> = (props) => {
  return (
    <div className={styles.container}>
      <div className={styles.textBox}>
        <p>{props.children}</p>
      </div>
    </div>
  );
};

export default RoundTextBox;
