import styles from "./PremissasUsinaMobile.module.css";
import { usePropostaContext } from '../../../context/PropostaContext';

const PremissasUsinaMobile = () => {
    const { proposta } = usePropostaContext();
    return (
        <div className={styles.boxInfosContainer}>
            <div className={styles.boxInfos}>
                <div className={styles.boxInfosTitulos}>
                    <h2>Capacidade Instalada</h2>
                    <h2>Inflação Energética</h2>
                    <h2>Perda de eficiência</h2>
                    <h2>IPCA</h2>
                    <h2>Overload</h2>
                    <h2>Potência Pico</h2>
                    <h2>CAPEX [R$/MWp]</h2>
                    <h2>CAPEX [R$]</h2>
                    <h2>Regime Tributário</h2>
                </div>
                <div className={styles.boxInfosValores}>
                    <h2>{`${proposta?.potenciaInversor}kW`}</h2>
                    <h2>{`${proposta ? Math.round(proposta?.inflacaoEnergetica * 100) : 0}% a.a`}</h2>
                    <h2>{`${proposta?.perdaEficiencia}% a.a`}</h2>
                    <h2>{`${proposta?.ipca}% a.a`}</h2>
                    <h2>{`${proposta?.overload}%`}</h2>
                    <h2>{`${proposta?.potenciaPico} kWp`}</h2>
                    <h2>{`${proposta?.realPorWp.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}</h2>
                    <h2>{`${proposta?.capex.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}</h2>
                    <h2>{proposta?.regimeTributario}</h2>
                </div>
            </div>
        </div>
    );
};


export default PremissasUsinaMobile;
