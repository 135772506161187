import styles from "./ResultadosFinanceiros.module.css";
import moneyIcon from '../../../assets/icons/money-icon.svg';
import { usePropostaContext } from '../../../context/PropostaContext';
import ResultadosFinanceiros from "../../../components/resultadosText/ResultadosFinanceiros";

const ResultadosFinanceirosSection = () => {
    const { proposta } = usePropostaContext();

    return (
        <div className={styles.bgSection}>
            <div className={styles.section}>
                <div className={styles.sectionHeader}>
                    <div className={styles.titleSection}>
                        <img
                            src={moneyIcon}
                            alt=""
                            className={styles.moneyIcon}
                        />
                        <div className={styles.boxGestaoPlin}>
                            <h1 className={styles.title}>Resultados Financeiros</h1>
                        </div>
                    </div>
                    <div className={styles.resumoProposta}>
                        <div className={styles.boxResultadosFinanceiros}>
                            <div className={styles.boxResultados}>
                                <ResultadosFinanceiros
                                    title="TIR do Projeto"
                                    values={proposta?.tirDoProjeto != null
                                        ? `${proposta?.tirDoProjeto}%`
                                        : "..."}
                                />
                                <ResultadosFinanceiros
                                    title="Payback"
                                    values={proposta?.payback != null
                                        ? `${proposta?.payback} anos`
                                        : "..."}
                                />
                                <ResultadosFinanceiros
                                    title="VPL de 25 anos"
                                    values={proposta?.vpl != null
                                        ? `${proposta?.vpl.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                                        : "..."}
                                />
                                <ResultadosFinanceiros
                                    title="Rentabilidade média 10 anos"
                                    values={proposta?.rentabilidadeMedia != null
                                        ? `${(proposta?.rentabilidadeMedia)}%`
                                        : "..."}
                                />
                            </div>
                            <div className={styles.boxResultados}>
                                <ResultadosFinanceiros
                                    title="Receita Líquida SPE"
                                    values={proposta?.fluxoDeCaixa[0].receitaLiquida != null
                                        ? `${proposta?.fluxoDeCaixa[0].receitaLiquida.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                                        : "..."}
                                />
                                <ResultadosFinanceiros
                                    title="Tributos primeiro ano"
                                    values={proposta?.fluxoDeCaixa[0].tributos != null
                                        ? `${proposta ? (-1 * proposta.fluxoDeCaixa[0].tributos).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ""}`
                                        : "..."}
                                />
                                <ResultadosFinanceiros
                                    title="Lucro Líquido"
                                    values={proposta?.fluxoDeCaixa[0].lucroLivreDeImposto != null
                                        ? `${proposta?.fluxoDeCaixa[0].lucroLivreDeImposto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`
                                        : "..."}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.divisionLine} />
        </div>
    );
};


export default ResultadosFinanceirosSection;
