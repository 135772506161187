import styles from "./Header.module.css";
import logoPlin from "../../../assets/marca-plin.svg"
import userIcon from "../../../assets/icons/user-icon.svg";
import { usePropostaContext } from "../../../context/PropostaContext";


const Header = () => {
    const { proposta } = usePropostaContext();
    return (
        <>
            <div className={`${styles.header} ${styles.headerContainer}`}>
                <p className={styles.headerText}>Proposta Comercial</p>
                <div className={styles.headerRight}>
                    <img src={logoPlin} className={styles.logoPlinHeader} />
                    <p>
                        Economia
                        <br /> de energia
                        <br />
                        simples assim
                    </p>
                </div>
            </div>
            <div className={styles.headerSecondary}>
                <div className={styles.companyName}>
                    <img
                        className={styles.userIcon}
                        src={userIcon}
                        alt="Nome da pessoa"
                    />
                    <p className={styles.headerSecondaryText}>
                        {proposta?.name || "..."}
                    </p>
                </div>
                <div className={styles.averageHeader}>
                    <div className={styles.averageLeft}>
                        <p>
                            {proposta?.capex != null 
                            ? `CAPEX (valor investido): ${proposta?.capex.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} `
                             : "CAPEX (valor investido): ..." 
                            }
                        </p>
                    </div>
                    <div className={styles.averageRight}>
                        <p>
                            {proposta?.potenciaInversor != null
                                ? `Potência da Usina: ${proposta.potenciaInversor} kW`
                                : "Potência da Usina: ..."
                            }
                        </p>
                    </div>
                </div>

            </div>
        </>
    )
}


export default Header;