import React from 'react';
import { usePropostaContext } from '../../../context/PropostaContext';
import styles from './SideButtons.module.css';
import printerIcon from "../../../assets/icons/printer-icon.svg";
import chatIcon from "../../../assets/icons/chat-icon.svg";
import ButtonSideIcon from '../../../components/buttonSideIcon/ButtonSideIcon';

const SideButtons = () => {
    const { proposta } = usePropostaContext();

    const handlePrint = () => {
        document.querySelector("#tabelaFluxo")?.scrollIntoView();
        setTimeout(() => {
            window.print();
        }, 500);
    };

    const whatsappText = `Olá, quero comercializar minha energia, tenho uma usina que a distribuidora é a ${proposta?.distribuidora.nome}, com potência de ${proposta?.potenciaInversor}kWh.\n\nAguardo seu contato.`;
    const whatsappLink = `https://wa.me/554499868139?text=${encodeURIComponent(whatsappText)}`;

    return (
        <div className={styles.sideMenu}>
            <ButtonSideIcon
                overlayImage={printerIcon}
                buttonText="Imprimir"
                onClick={handlePrint}
            />
            <ButtonSideIcon
                overlayImage={chatIcon}
                buttonText="Contato"
                href={whatsappLink}
            />
        </div>
    );
};

export default SideButtons;
