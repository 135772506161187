import styles from "./PowerNumbers.module.css";
import coinIcon from "../../../assets/icons/coin-icon.svg";
import RoundTextBox from "../../../components/roundBox/RoundTextBox";
import PowerNumbersCard from "../../../components/powerNumbersCard/PowerNumberCards";
import { usePropostaContext } from "../../../context/PropostaContext";
import SideButtons from "../SideButtons/SideButtons";

const PowerNumbers = () => {
    const { proposta } = usePropostaContext();

    return (
        <div className={styles.bgSection1}>
            <div className={styles.section1}>
                <div className={styles.section1Header}>
                    <div className={styles.titleSection}>
                        <img
                            src={coinIcon}
                            alt=""
                            className={styles.coinIcon}
                        />
                        <div className={styles.boxVendaEnergia}>
                            <h1 className={styles.title}>Venda sua energia com a PLIN</h1>
                            <div className={styles.roundedTextBox}>
                                <RoundTextBox>transparência</RoundTextBox>
                                <RoundTextBox>tecnologia</RoundTextBox>
                                <RoundTextBox>gestão</RoundTextBox>
                                <RoundTextBox>venda 100% da sua energia</RoundTextBox>
                            </div>
                        </div>
                    </div>
                </div>
                <SideButtons />
                <div className={styles.powerNumbersGrid}>
                    <PowerNumbersCard
                        textNumbers={proposta?.rentabilidadeMedia != null ? `${proposta.rentabilidadeMedia}%` : "..."}
                        textDesc="Rentabilidade média"
                    />
                    <PowerNumbersCard
                        textNumbers={proposta?.valorkWh != null ? `${proposta.valorkWh.toLocaleString('pt-BR', { currency: 'BRL' })} R$/kWh` : "..." }
                        textDesc="Valor do kWh"
                    />
                    <PowerNumbersCard
                        textNumbers={proposta?.fluxoDeCaixa[0].receitaLiquida != null ? `${proposta?.fluxoDeCaixa[0].receitaLiquida.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`: "..."}
                        textDesc="Receita anual"
                    />
                    <PowerNumbersCard
                        textNumbers={proposta?.payback != null ? `${proposta?.payback} anos` : "..."}
                        textDesc="payback"
                    />
                    <PowerNumbersCard
                        textNumbers={proposta?.geracaoAnual != null ? `${proposta?.geracaoAnual}kWh` : "..."}
                        textDesc="geração anual"
                    />

                </div>
            </div>
        </div>
    )

}


export default PowerNumbers;