import styles from "./Icon.module.css";

interface IconProps {
	icon: string;
}

export function Icon(props: IconProps) {
	return (
		<img
			src={props.icon}
			className={styles.icon}
			alt=""
		/>
	);
}
