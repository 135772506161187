import React from "react";
import styles from "./ButtonSideIcon.module.css";

interface ButtonSideIconProps {
  overlayImage: string;
  buttonText: string;
  onClick?: () => void;
  href?: string;
  target?: string;
}

const ButtonSideIcon: React.FC<ButtonSideIconProps> = ({
  overlayImage,
  buttonText,
  onClick,
  href,
  target,
}) => {
  const ContainerComponent = href ? "a" : "div";

  const additionalProps = href
    ? { href, target: target || "_blank" } 
    : {};

  return (
    <ContainerComponent
      className={styles.buttonContainer}
      onClick={onClick}
      {...additionalProps}
    >
      <div className={styles.gradientButton}>
        <img
          className={styles.iconImage}
          src={overlayImage}
          alt="Icon"
        />
      </div>
      <span className={styles.textButton}>{buttonText}</span>
    </ContainerComponent>
  );
};

export default ButtonSideIcon;
