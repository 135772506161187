import FotterButtons from "./FotterButtons/Buttons";
import styles from "./PrintProposta.module.css"
import Header from "./header/Header";
import PowerNumbers from "./powerNumbers/PowerNumbers";
import GestaoPlin from "./gestaoPlin/GestaoPlin";
import EficienciaTributaria from "./eficienciaTributaria/EficienciaTributaria";
import ResumoProposta from "./resumoProposta/ResumoProposta";
import OfertaFixa from "./ofertaFixa/OfertaFixa";
import ResultadosFinanceiros from "./resultadosFinanceiros/ResultadosFinanceiros";
import PremissasUsina from "./premissasUsina/PremissasUsina";
import SectionFluxoCaixa from "./sectionFluxoCaixa/sectionFluxoCaixa";
import FotterButtonsMobile from "./FotterButtonsMobile/FotterButtonsMobile";

const PrintProposta = () => {
    return (
        <div className={styles.container}>
            <Header />
            <PowerNumbers />
            <GestaoPlin />
            <EficienciaTributaria />
            <SectionFluxoCaixa />
            <ResumoProposta />
            <OfertaFixa />
            <ResultadosFinanceiros />
            <PremissasUsina />
            <FotterButtons />
            <FotterButtonsMobile />
        </div>

    )

}

export default PrintProposta;