import styles from "./ResultadosFinanceiros.module.css";
import blueRectangle from "../../assets/blue-reactangle.svg";

interface ResultadosFinanceirosProps {
  title: string;
  values: number | string;
}

const ResultadosFinanceiros: React.FC<ResultadosFinanceirosProps> = ({
  title,
  values,
}) => {
  return (
    <div className={styles.imgAndTitle}>
      <img src={blueRectangle} alt="Imagem" className={styles.iconImage} />
      <h2>{title}</h2>
      <h1>{values}</h1>
    </div>
  );
};

export default ResultadosFinanceiros;
