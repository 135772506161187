import React from "react";
import styles from "./GestaoPlin.module.css";
import plinIcon from "../../../assets/icons/plin-icon.svg";
import desktopImg from "../../../assets/desktop-phone.png";

const GestaoPlin = () => {
    return (
        <div className={styles.bgSection}>
            <div className={styles.section}>
                <div className={styles.sectionHeader}>
                    <div className={styles.contentContainer}>
                        <div className={styles.titleSection}>
                            <img
                                src={plinIcon}
                                alt=""
                                className={styles.plinIcon}
                            />
                            <div className={styles.boxGestaoPlin}>
                                <h1 className={styles.title}>Gestão PLIN Energia</h1>
                            </div>
                        </div>
                        <div className={styles.gestaoText}>
                            <p>Plataforma, dashboards e indicadores;</p>
                            <p>Comercialização;</p>
                            <p>Gestão de Créditos;</p>
                            <p>Envio de Relatórios e Customer Experience;</p>
                            <p>Controle e gestão dos dados;</p>
                            <p>Suporte ao investidor;</p>
                        </div>
                    </div>
                    <div className={styles.photosContainer}>
                        <img src={desktopImg} className={styles.desktopImg} alt="Imagem computador" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GestaoPlin;
