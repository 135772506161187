import React, { useState, useRef, useEffect } from 'react';
import TableComponent from "../../../../components/tableComponent/tableComponent";
import { DistribuidoraDados, FluxoDeCaixaItem } from "../../../../interfaces/IProposta";
import styles from "./tableFluxoCaixa.module.css";

interface TableFluxoCaixaProps {
    fluxoDeCaixa: Array<FluxoDeCaixaItem>;
    distribuidora: DistribuidoraDados;
    isModalDeleteLeituraOpen?: boolean;
    setIsModalDeleteLeituraOpen?: any;
}

const TableFluxoCaixa: React.FC<TableFluxoCaixaProps> = ({
    fluxoDeCaixa = [],
    distribuidora,
}) => {

    const [isVisible, setIsVisible] = useState(false);
    const tableRef = useRef<HTMLDivElement>(null);
    const [animationCount, setAnimationCount] = useState(0);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.5, // Percentage of the element visible in the viewport
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        if (tableRef.current) {
            observer.observe(tableRef.current);
        }

        return () => {
            if (tableRef.current) {
                observer.unobserve(tableRef.current);
            }
        };
    }, []);

    const headerData = [
        "Ano",
        "Tarifa por MWh",
        "Geração",
        "Receita",
        "Lucro Livre",
        "Rentabilidade",
        "Montante",
    ];

    const renderHeaders = (headers: any) => {
        return headers.map((header: any, index: any) => (
            <th key={index}>{header}</th>
        ));
    };

    const anos = Array.from({ length: 10 }, (_, index) => index + 1);

    const renderRows = () => {
        return anos.map((ano, i) => {
            const rowClass = i % 2 === 0 ? styles.evenRow : styles.oddRow;
            const rowData = fluxoDeCaixa[i];


            return (
                <tr key={i} className={rowClass} >
                    <td className={styles.rowAno}>{ano}º Ano</td>
                    <td>{`${rowData.boletagem.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` || ""} </td>
                    <td>{rowData?.geracaoAnual || ""} MWh</td>
                    <td>{`${rowData?.receitaLiquida.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` || ""}</td>
                    <td>{`${rowData?.lucroLivreDeImposto.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` || ""}</td>
                    <td>{rowData ? (rowData.rentabilidade) : 0 || ""}%a.a</td>
                    <td>{`${rowData?.montanteDoFluxoDeCaixa.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` || ""}</td>
                </tr>
            );
        });
    };
    const onAnimationEnd = () => {
        if (animationCount < 2) {
            setAnimationCount(animationCount + 1);
        }
    };

    return (
        <div id="tabelaFluxo" className={styles.tableFluxo} ref={tableRef}>
            {isVisible && animationCount < 3 && (
                <div className={`${styles.tableFluxo} ${isVisible ? styles['shake-animation'] : ''} tableFluxo`} style={{ overflowX: 'auto' }} onAnimationEnd={onAnimationEnd}>
                    <TableComponent
                        headersData={headerData}
                        renderHeaders={renderHeaders}
                        renderRows={renderRows}
                    />
                </div>
            )}
        </div>
    );

};

export default TableFluxoCaixa;
