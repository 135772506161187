import { Primary } from "./primary/Primary";
import { Icon } from "./icon/Icon";
import { Label } from "./label/Label";
import { Cancel } from "./cancel/Cancel";
import { Secondary } from "./secondary/Secondary";

export const Button = {
	Primary,
	Icon,
	Label,
	Cancel,
	Secondary,
};
