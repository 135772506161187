import React from "react";
import styles from "./tableComponent.module.css"

interface TableComponentParams {
    className?: string;
    rowsData?: Array<object>;
    headersData?: Array<string>;
    renderHeaders(headers: any): Array<React.ReactNode>;
    renderRows(row: any): Array<React.ReactElement>;
}

const TableComponent: React.FC<TableComponentParams> = ({
    className = "",
    rowsData = [],
    headersData = [],
    renderHeaders,
    renderRows,
}) => {
    const headers = renderHeaders(headersData);
    const rows = renderRows(rowsData);

    return (
        <>
            <table className={`${styles.table} ${className}`}>
                <thead className={styles.tableHead}>
                    <tr>{headers}</tr>
                </thead>
                <tbody>
                    {(() => {
                        return rows;
                    })()}
                </tbody>
            </table>
        </>
    );
};


export default TableComponent;
