import React from 'react';
import styles from "./ResumoProposta.module.css";
import simboloPlin from "../../../assets/icons/simboloPlin-icon.svg";
import { fakeApi } from '../../../utils/fakeApi';
import HeaderPrinter from '../../../components/headerPrint/HeaderPrinter';
import { usePropostaContext } from '../../../context/PropostaContext';
import ResumoPropostaMobile from './ResumoPropostaMobile';

const ResumoProposta = () => {
    const { proposta } = usePropostaContext();

    return (
        <React.Fragment>
            <HeaderPrinter />
            <div className={styles.bgSection}>
                <div className={styles.section}>
                    <div className={styles.sectionHeader}>
                        <div className={styles.contentContainer}>
                            <div className={styles.titleSection}>
                                <img
                                    src={simboloPlin}
                                    alt=""
                                    className={styles.simboloPlin}
                                />
                                <div className={styles.boxGestaoPlin}>
                                    <h1 className={styles.title}>Resumo da Proposta</h1>
                                </div>
                            </div>
                            <div className={styles.resumoPropostaDesktop}>
                                <div className={styles.boxInfos}>
                                    <div className={styles.boxInfosTitulos}>
                                        <h2>Usina</h2>
                                        <h2>Modelo da Proposta</h2>
                                        <h2>Dados da Usina</h2>
                                        <h2>Distribuidora</h2>
                                        <h2>Locador</h2>
                                        <h2>Prazo do Contrato</h2>
                                        <h2>Reajuste do Contrato</h2>
                                    </div>
                                    <div className={styles.boxInfosValores}>
                                        <h2>UFV {proposta?.potenciaInversor} kW {proposta?.city} - {proposta?.state}</h2>
                                        <h2>Locação de Equipamentos</h2>
                                        <h2>{`Geração de ${proposta?.geracaoMes} MWh/mês`}</h2>
                                        <h2>{proposta?.distribuidora.nome}</h2>
                                        <h2>Consórcio PLIN Energia</h2>
                                        <h2>1 a 10 anos</h2>
                                        <h2>Anualmente, de acordo com a tarifa da distribuidora</h2>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.resumoPropostaMobile}>
                                <ResumoPropostaMobile />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.divisionLine} />
            </div>
        </React.Fragment>
    );
};


export default ResumoProposta;
