import styles from "./HeaderPrinter.module.css";
import plinLogo from "../../assets/marca-plin.svg";
import { usePropostaContext } from "../../context/PropostaContext";
const HeaderPrinter = () => {
    const { proposta } = usePropostaContext();
    return (
        <div className={styles.header}>
            <img src={plinLogo} className={styles.plinLogo} alt="Logo PLIN Energia" />
            <div className={styles.infoContainer}>
                <div className={styles.leftText}>
                    <h2>Proposta comercial</h2>
                </div>
                <div className={styles.righText}>
                    <h2>{proposta?.name}</h2>
                </div>
            </div>
        </div>
    )
}

export default HeaderPrinter;