import { createContext, useContext, useEffect, useState } from "react";
import { PropostaServices } from "../services/PropostaService";
import { useParams } from "react-router-dom";
import { DistribuidoraDados, PropostaData } from "../interfaces/IProposta";


interface PropostaContextType {
  proposta: PropostaData | null;
  distribuidora: DistribuidoraDados | null;
  setDistribuidora: React.Dispatch<React.SetStateAction<DistribuidoraDados | null>>;
  setProposta: React.Dispatch<React.SetStateAction<PropostaData | null>>;
}

const PropostaContext = createContext<PropostaContextType | undefined>(undefined);
export const PropostaProvider = ({ children, propostaId }: { children: React.ReactNode, propostaId: string | null }) => {
  const [proposta, setProposta] = useState<PropostaData | null>(null);
  const [distribuidora, setDistribuidora] = useState<DistribuidoraDados | null>(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await PropostaServices.getProposta(propostaId);
        const responseData = response.data;
        setProposta(responseData);
        setDistribuidora(responseData)
      } catch (error) {
        console.error('Erro ao obter os dados da API:', error);
      }
    }
    fetchData();
  }, [propostaId]);

  return (
    <PropostaContext.Provider value={{ distribuidora, setDistribuidora, proposta, setProposta }}>
      {children}
    </PropostaContext.Provider>
  );
};

export const usePropostaContext = () => {
  const context = useContext(PropostaContext);
  if (!context) {
    throw new Error('Precisa de um provider..');
  }
  return context;
};
