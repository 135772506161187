import React from 'react';
import styles from "./ResumoPropostaMobile.module.css";
import HeaderPrinter from '../../../components/headerPrint/HeaderPrinter';
import { usePropostaContext } from '../../../context/PropostaContext';

const ResumoPropostaMobile = () => {
    const { proposta } = usePropostaContext();
    return (
        <React.Fragment>
            <HeaderPrinter />
            <div className={styles.boxInfos}>
                <div className={styles.boxInfosProposta}>
                    <h2>Usina</h2>
                    <h1>UFV {proposta?.potenciaInversor}MW {proposta?.city} - {proposta?.state}</h1>
                    <h2>Modelo da Proposta</h2>
                    <h1>Locação de Equipamentos</h1>
                    <h2>Dados da Usina</h2>
                    <h1>{`Geração de ${proposta?.geracaoMes} MWh/mês`}</h1>
                    <h2>Distribuidora</h2>
                    <h1>{proposta?.distribuidora.nome}</h1>
                    <h2>Locador</h2>
                    <h1>Consórcio PLIN Energia</h1>
                    <h2>Prazo do Contrato</h2>
                    <h1>1 a 10 anos</h1>
                    <h2>Reajuste do Contrato</h2>
                    <h1>Anualmente, de acordo com a tarifa da distribuidora</h1>
                </div>
            </div>
        </React.Fragment>
    );
};


export default ResumoPropostaMobile;
