import { ReactNode } from "react";
import styles from "./PowerNumberCards.module.css";

interface PowerNumbersCardProps {
  textNumbers: string;
  textDesc?: string;
  textColor?: string;
  fontWeight?: string;
  fontSize?: string;
  maxWidth?: string;
}

const PowerNumbersCard: React.FC<PowerNumbersCardProps> = ({
  textNumbers,
  textDesc,
  textColor = "#1B29F0",
  fontWeight = "600",
  fontSize,
  maxWidth,
}) => {

  const textNumbersStyle = {
    color: textColor,
    fontWeight: fontWeight,
    fontSize: fontSize,
  };

  const containerStyle = {
    maxWidth: maxWidth
  };

  return (
    <div className={styles.container} style={containerStyle}>
      <div className={styles.textBox}>
        <h2 className={styles.textNumbers} style={textNumbersStyle}>
          {textNumbers}
        </h2>
        {textDesc && <h3 className={styles.textDesc}>{textDesc}</h3>}
      </div>
    </div>
  );
};

export default PowerNumbersCard;
