import styles from "./EficienciaTributaria.module.css";
import handIcon from "../../../assets/icons/handCoin-icon.svg"

const EficienciaTributaria = () => {
    return (
        <div className={styles.bgSection}>
            <div className={styles.section}>
                <div className={styles.sectionHeader}>
                    <div className={styles.contentContainer}>
                        <div className={styles.titleSection}>
                            <img
                                src={handIcon}
                                alt=""
                                className={styles.handIcon}
                            />
                            <div className={styles.boxGestaoPlin}>
                                <h1 className={styles.title}>Eficiência tributária</h1>
                            </div>
                        </div>
                        <div className={styles.eficienciaText}>
                            <span className={styles.firstText}>
                                <p>Com nosso modelo de negócio voltado para  locação de equipamentos,
                                    trabalhamos  apenas com
                                    <span> pessoa jurídica</span>
                                    , resultando em
                                    <span> 9,5% até 21,5% a menos de tributação.</span>
                                </p>
                            </span>
                            <span className={styles.secondText}>
                                <p>
                                    Caso não possua um CNPJ, ou tenha dúvidas entre em contato com sua contabilidade.
                                </p>
                            </span>
                        </div>
                    </div>
                    <div className={styles.graphSection}>
                        <div className={styles.graphContainer}>
                            <h2 className={styles.graphTitle}>Imposto de Renda PJ</h2>
                            <div className={styles.graphPj}>
                                <div className={styles.barsContainer}>
                                    <div className={`${styles.bar} ${styles.lightBlue}`} style={{ width: '55%' }}></div>
                                    <div className={`${styles.bar} ${styles.darkBlue}`} style={{ width: '35%' }}></div>
                                </div>
                                <h3 className={styles.porcentagemPj}>9% a 21,5%</h3>
                            </div>
                        </div>
                        <div className={styles.graphContainer}>
                            <h2 className={styles.graphTitle}>Imposto de Renda PF</h2>
                            <div className={styles.graphPf}>
                                <div className={styles.barsContainer}>
                                    <div className={`${styles.bar} ${styles.darkGray}`} style={{ width: '75%' }}></div>
                                </div>
                                <h3 className={styles.porcentagemPf}>27%</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EficienciaTributaria;
