import styles from "./OfertaFixaMobile.module.css";
import flashLightIcon from "../../../assets/icons/flashlight-icon.svg";
import PowerNumbersCard from "../../../components/powerNumbersCard/PowerNumberCards";
import { usePropostaContext } from "../../../context/PropostaContext";
const OfertaFixaMobile = () => {
    const { proposta } = usePropostaContext();
    return (
        <>
            <div className={styles.section1Header}>
                <div className={styles.titleSection}>
                    <img
                        src={flashLightIcon}
                        alt=""
                        className={styles.flashLightIcon}
                    />
                    <h1 className={styles.title}>Oferta fixa conforme tarifa vigente da distribuidora</h1>
                </div>
            </div>
            <div className={styles.cardContainer}>
                <div className={styles.primaryCard}>
                    <PowerNumbersCard
                        textNumbers={`${proposta?.taxaMinimaPorMWhNaoDescontada.toLocaleString('pt-BR', { currency: 'BRL' })} R$ / MWh` || ""}
                        fontWeight="600"
                        maxWidth="23rem"
                        fontSize="2rem"
                    />
                    <p>Valor bruto da oferta</p>
                </div>
                <div className={styles.secondCard}>
                    <PowerNumbersCard
                        textNumbers={`${proposta?.taxaMinimaPorMWhDescontada.toLocaleString('pt-BR', { currency: 'BRL' })} R$ / MWh` || ""}
                        textColor="#757575"
                        fontWeight="600"
                        maxWidth="23rem"
                        fontSize="2rem"
                    />
                    <p>Valor líquido da oferta</p>
                </div>
                <div className={styles.ofertaDesc}>Obtemos o valor líquido <br /> descontando a taxa <br /> mínima da distribuidora</div>
            </div>
            <div className={styles.divisionLine} />
        </>
    )

}


export default OfertaFixaMobile;